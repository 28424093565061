// src/components/Team.js
import React from 'react';
import './Team.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const teamMembers = [
    {
        id: 1,
        name: "Aalok Shaw",
        role: "CEO",
        image: "/aalok.JPG",  // Ensure the path is correct
        socialLinks: {
            linkedin: "/"
        }
    },
    {
        id: 2,
        name: "Soumi Guria",
        role: "App Developer",
        image: "/soumi.jpeg", // Ensure the path is correct
        socialLinks: {
            linkedin: "/"
        }
    },
    {
        id: 2,
        name: "Susmit Shaw",
        role: "Product Manager",
        image: "/WhatsApp Image 2024-12-27 at 13.14.34 (1).jpeg", // Ensure the path is correct
        socialLinks: {
            linkedin: "/"
        }
    },
    {
        id: 3,
        name: "Dibyendu Mandal",
        role: "Lead Developer",
        image: "/IMG-20240225-WA0285.jpg", // Ensure the path is correct
        socialLinks: {
            linkedin: "/"
        }
    },
];

const Team = () => {
    return (
        <div className="team-container">
            <h2 className="team-title">Meet Our Team</h2>
            <div className="team-grid">
                {teamMembers.map(member => (
                    <div className="team-card" key={member.id}>
                        <img src={member.image} alt={`${member.name} - ${member.role}`} className="team-image" />
                        <h3 className="team-name">{member.name}</h3>
                        <p className="team-role">{member.role}</p>
                        <div className="social-icons">
                            <a href={member.socialLinks.facebook} className="social-icon">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href={member.socialLinks.twitter} className="social-icon">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href={member.socialLinks.linkedin} className="social-icon">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Team;